import { Menu, MenuItem } from '@material-ui/core';
import { toUrl } from 'gatsby-source-gravatar';
import { useTranslation } from 'react-i18next';
import React, { memo, useContext, useMemo, useState } from 'react';
import cx from 'classnames';
import { navigate } from 'gatsby';
import UserContext from '../../contexts/UserContext';
import { handleKeyUp } from '../../utils';
import * as styles from './Avatar.module.css';

const Avatar = ({ className }) => {
  const { t } = useTranslation();
  const { user, logout } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleGoToApp = () => navigate('/app/dashboard');
  const handleGoToAdmin = () => navigate('/app/adminboard');
  const handleGoToSuperAdmin = () => navigate('/app/superadminboard');
  const handleSettings = () => navigate('/app/settings');
  const handlePrivate = () => navigate('/app/private');

  const handleLogout = () => {
    logout();
    handleClose();
  };

  const photoURL = useMemo(
    () => toUrl((user && user.email) || '', 'size=128&d=retro'),
    [user && user.email],
  );

  return (
    <div>
      {user && (
        <div>
          <div
            tabIndex="0"
            role="button"
            className="flex focus:outline-none"
            onClick={handleClick}
            onKeyUp={(e) => handleKeyUp(e, handleClick)}
          >
            <img
              src={photoURL}
              alt={user.displayName || 'Anonymous User'}
              className={cx(styles.container, className)}
            />
          </div>
          <Menu
            keepMounted
            anchorEl={anchorEl}
            onClose={handleClose}
            open={Boolean(anchorEl)}
          >
            <MenuItem
              className={styles.menuContentLeft}
              onClick={handleSettings}
            >
              {t('landing.hero.settings')}
            </MenuItem>
            <MenuItem
              className={styles.menuContentLeft}
              onClick={handlePrivate}
            >
              {t('landing.hero.private')}
            </MenuItem>
            {(user.adminCompanyId || user.managerCompanyId) && (
              <MenuItem
                className={styles.menuContentLeft}
                onClick={handleGoToApp}
              >
                {t('landing.hero.goToApp')}
              </MenuItem>
            )}
            {user.adminCompanyId && (
              <MenuItem
                className={styles.menuContentLeft}
                onClick={handleGoToAdmin}
              >
                {t('landing.hero.goToAdmin')}
              </MenuItem>
            )}
            {user.isSuperAdmin && (
              <MenuItem
                className={styles.menuContentLeft}
                onClick={handleGoToSuperAdmin}
              >
                {t('landing.hero.goToSuperAdmin')}
              </MenuItem>
            )}
            <MenuItem
              className={cx([styles.menuContentLeft, styles.menuWarning])}
              onClick={handleLogout}
            >
              {t('shared.buttons.logout')}
            </MenuItem>
          </Menu>
        </div>
      )}
    </div>
  );
};

export default memo(Avatar);
